// import React from "react";
// import { graphql, useStaticQuery } from "gatsby";
// import { getImage, GatsbyImage, StaticImage } from "gatsby-plugin-image";
// const GatsbyImageCustom = (props) => {
//   const Image = useStaticQuery(graphql`
//     query {
//       allSanityImageAsset {
//         nodes {
//           _id
//           url
//           localFile {
//             childImageSharp {
//               gatsbyImageData
//             }
//           }
//         }
//       }
//     }
//   `);
//   // return <div>{console.log(Image.allSanityImageAsset.nodes.find(aa=>aa.id===id).gatsbyImageData,id, "Image")}</div>;

//   const ImageData = Image.allSanityImageAsset.nodes.find(
//     (aa) => aa._id === props.id
//   );
//   // return null;
//   return (
//     <GatsbyImage
//       image={getImage(ImageData.localFile.childImageSharp.gatsbyImageData)}
//       {...props}
//       className={props.classes ? props.classes : ""}
//     />
//   );
// };

// export default GatsbyImageCustom;

import React from "react";
import { graphql, useStaticQuery } from "gatsby";
// import { getImage, GatsbyImage, StaticImage } from "gatsby-plugin-image";
const GatsbyImageCustom = (props) => {
  const Image = useStaticQuery(graphql`
    query {
      allSanityImageAsset {
        nodes {
          _id
          url
        }
      }
    }
  `);
  // return <div>{console.log(Image.allSanityImageAsset.nodes.find(aa=>aa.id===id).gatsbyImageData,id, "Image")}</div>;

  const ImageData = Image.allSanityImageAsset.nodes.find(
    (aa) => aa._id === props.id
  );
  // console.log(ImageData.localFile.childImageSharp.fixed.src,"sad")
  // return null;

  return ImageData ? (
    <img alt="head" src={ImageData.url} {...props} className={props.className ?props.className :props.classes } />
  ) : (
    "Not Found"
  );
};

export default GatsbyImageCustom;

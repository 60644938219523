import React from "react";
import { Link } from "gatsby";
import GatsbyImageCustom from "../Image/GatsbyImageCustom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Img from "../../assets/images/blog7.jpg";
import {
  faFacebookSquare,
  faInstagram,
  // faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import moment from "moment";
// import {
//   FacebookIcon,
//   FacebookShareButton,
//   LinkedinIcon,
//   LinkedinShareButton,
//   TwitterIcon,
//   TwitterShareButton,
//   WhatsappIcon,
//   WhatsappShareButton,
// } from "react-share";

const Footer = (props) => {
  // console.log(props, "gh");
  return (
    <>
      <div className="bg-secondary-darkblue-500">
        <div className=" px-4 pt-20 pb-10 mx-auto max-w-6xl">
          <div className="pb-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
            <div className="px-2 pb-8 lg:pb-0">
              <div className="p-2.5 mb-3 text-base font-bold text-white  uppercase border-b-2 border-white lg:border-0 ">
                {
                  props.data.footerblock.footer_widget_section[0]
                    .widget_section_menu._rawMenuBlock[0].sub_menu_section
                    .hassubmenu.add_menu_group[0].group_title
                }
              </div>
              <ul>
                {props.data.footerblock.footer_widget_section[0]
                  .widget_section_menu._rawMenuBlock[0].sub_menu_section
                  .hassubmenu.add_menu_group[0].add_sub_menu_items &&
                  props.data.footerblock.footer_widget_section[0].widget_section_menu._rawMenuBlock[0].sub_menu_section.hassubmenu.add_menu_group[0].add_sub_menu_items.map(
                    (aa, idx) => (
                      <Link
                        key={idx}
                        to={
                          aa.sub_menu_item_link === undefined
                            ? "#"
                            : aa.sub_menu_item_link
                        }
                      >
                        <li className="text-sm font-medium text-white px-2.5 pb-2.5 hover:text-gray-400 cursor-pointer">
                          {aa.sub_menu_item_title}
                        </li>
                      </Link>
                    )
                  )}
              </ul>
            </div>

            <div className="px-2 pb-8 lg:pb-0">
              <div className="p-2.5 mb-3 text-base font-bold text-white  uppercase border-b-2 border-white lg:border-0 ">
                {
                  props.data.footerblock.footer_widget_section[0]
                    .widget_section_menu._rawMenuBlock[0].sub_menu_section
                    .hassubmenu.add_menu_group[1].group_title
                }
              </div>
              <ul>
                {props.data.footerblock.footer_widget_section[0]
                  .widget_section_menu._rawMenuBlock[0].sub_menu_section
                  .hassubmenu.add_menu_group[1].add_sub_menu_items &&
                  props.data.footerblock.footer_widget_section[0].widget_section_menu._rawMenuBlock[0].sub_menu_section.hassubmenu.add_menu_group[1].add_sub_menu_items.map(
                    (aa, idx) => (
                      <Link
                        key={idx}
                        to={
                          aa.sub_menu_item_link === undefined
                            ? "#"
                            : aa.sub_menu_item_link
                        }
                      >
                        <li
                          key={idx}
                          className="text-sm font-medium text-white px-2.5 pb-2.5 hover:text-gray-400 cursor-pointer"
                        >
                          {aa.sub_menu_item_title}
                        </li>
                      </Link>
                    )
                  )}
              </ul>
            </div>
            <div className="px-2 pb-8 lg:pb-0">
              <div className="p-2.5 mb-3 text-base font-bold text-white  uppercase border-b-2 border-white lg:border-0 ">
                {
                  props.data.footerblock.footer_widget_section[0]
                    .widget_section_menu._rawMenuBlock[0].sub_menu_section
                    .hassubmenu.add_menu_group[2].group_title
                }
              </div>
              <ul>
                {/* {props.data.footerblock.footer_widget_section[0]
                  .widget_section_menu._rawMenuBlock[0].sub_menu_section
                  .hassubmenu.add_menu_group[2].add_sub_menu_items &&
                  props.data.footerblock.footer_widget_section[0].widget_section_menu._rawMenuBlock[0].sub_menu_section.hassubmenu.add_menu_group[2].add_sub_menu_items.map(
                    (aa, idx) => (
                      <> */}
                <a href="tel:+91-9320793207">
                  <li className="text-sm font-medium text-white px-2.5 pb-2.5">
                    Tel. No.: 9320793207
                  </li>
                </a>
                <a href="mailto: rince@amethhyyst.com">
                  <li className="text-sm font-medium text-white px-2.5 pb-2.5">
                    Email : info@avgssgroup.com
                  </li>
                </a>
                {/* <a href="mailto: rince@amethhyyst.com"> */}
                <li className="text-sm  text-white px-2.5 pb-2.5">
                  <span className="font-extrabold text-base uppercase">Head Office :</span>{" "}
                  <span className="">
                    B 703 ONE BKC, G Block BKC, Bandra Kurla Complex, Bandra
                    East, Mumbai, Maharashtra 400051
                  </span>
                </li>
                {/* </a> */}
                {/* </>
                    )
                  )} */}
              </ul>
            </div>
            <div className="px-2 pb-8 lg:pb-0">
              <div className="p-2.5 mb-3 text-base font-bold text-white  uppercase border-b-2 border-white lg:border-0 ">
                Recent News
              </div>
              <ul>
                {props.newsdata._rawPagebuildersection[0].press_and_media_feilds_section
                  .sort((a, b) => new Date(b.news_date) - new Date(a.news_date))
                  .filter((aa, index) => index < 2)
                  .map((aa, idx) => {
                    // console.log(aa, "sad");
                    return (
                      <Link to={aa.news_link} key={idx}>
                        <li className=" pb-2.5">
                          <div className="flex">
                            <div className="w-16 h-16 mr-2">
                              <GatsbyImageCustom
                                id={aa.news_thumbnail.asset._ref}
                                className="w-16 h-16 object-cover"
                              />
                            </div>
                            <div className="flex flex-col text-white">
                              <div
                                className="text-sm truncate w-48 max-w-full"
                                // style={{textOverflow: "ellipsis", maxWidth: "150", overflow: "hidden" }}
                              >
                                {aa.news_title[0].children[0].text}
                              </div>
                              <div className="text-sm">
                                {moment(aa.news_date).format("MMMM DD, YYYY")}
                              </div>
                            </div>
                          </div>
                        </li>
                      </Link>
                    );
                  })}
              </ul>
            </div>
          </div>
          <div className="border-t border-white">
            <div className="lg:flex  justify-between md:block pt-6">
              {/* <div className="px-2 lg:block flex flex-col justify-center pb-8 lg:pb-0 col-auto md:col-span-2 lg:col-auto">
              <div className="object-contain mx-auto">
                <GatsbyImageCustom
                  id={props.data.footerblock._rawFooterLogo.asset._ref}
                />
              </div>
              <div className="text-white text-sm font-medium  px-2 text-center lg:text-left ">
                {
                  props.data.footerblock.footer_company_Address_Info[0]
                    ._rawChildren[0].text
                }
              </div>
            </div> */}
              <div className="md:block lg:grid grid-cols-12">
                {/* <div> */}
                <div className="flex justify-center lg:col-span-2">
                  <GatsbyImageCustom
                    id={props.data.footerblock._rawFooterLogo.asset._ref}
                    className="h-24 w-44"
                  />
                </div>
                <div className="flex justify-center text-center lg:text-left lg:col-span-6 px-2">
                  <div className=" font-normal text-base text-white max-w-xl lg:max-w-auto">
                    {/* {
                      props.data.footerblock.footer_company_Address_Info[0]
                        ._rawChildren[0].text
                    } */}
                    AVGSS Hospitality & Restaurants LLP currently holds 3
                    outlets in financial capital of India, Mumbai. Names
                    Amethhyyst, Amethhyyst XCI & Ametrine24. The names are
                    derived from positive & powerful stones like amethyst &
                    citrine. Amethhyyst & Ametrine 24 are located in the hub for
                    B-Town Andheri, whereas Amethhyyst XCI stands tall in
                    Mumbai’s posh residential area Breach Candy.
                  </div>
                </div>

                <div className="block col-span-4  text-white py-3">
                  <div className="flex justify-center lg:justify-end  cursor-pointer pb-3">
                    <Link
                      to="https://www.facebook.com/amethhyyst/"
                      target="_blank"
                      className="pr-4"
                    >
                      <FontAwesomeIcon icon={faFacebookSquare} size="lg" />
                    </Link>
                    <Link
                      to="https://www.instagram.com/avgssgroup/?igshid=YmMyMTA2M2Y%3D"
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faInstagram} size="lg" />
                    </Link>
                  </div>
                  <div className="text-base text-center lg:text-right">
                    {/* Copyright © 2021. All rights reserved. */}
                    Amethhyyst - Powered by AVGSS Group
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;

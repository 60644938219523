import React from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/index";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
// import Loader from "../../pages/loader";

const Layout = ({ children, location }) => {
  // console.log(location, "sad");

  // useEffect(() => {
  //   setTimeout(() => {
  //     try {
  //       const element = document.getElementById("full-page-loader");
  //       if (element) element.parentNode.removeChild(element);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }, 2000);
  // });

  const data = useStaticQuery(graphql`
    {
      allSanitySiteSettings {
        edges {
          node {
            headerblock {
              _rawHdrlogo
              _rawHdrnamelogo
              mainnavigation {
                menu_block {
                  menu_item_link
                  menu_item_title
                  menu_target_link
                  _rawSubMenuSection
                  menu_item_externallink
                }
              }
            }
            footerblock {
              _rawFooterLogo
              copy_right_text
              _rawFooterDescWidgetSection
              footer_company_Address_Info {
                _rawChildren
              }
              footer_widget_section {
                widget_section_menu {
                  _rawMenuBlock
                }
              }
            }
            socailmenublock {
              social_widget_section {
                social_item_icon_image_alt
              }
            }
          }
        }
      }
      sanityPage(_id: { eq: "508c3aea-0528-413d-9a14-8811a634dd47" }) {
        _id
        meta_title
        meta_desc
        title
        _rawPagebuildersection
      }
    }
  `);
  // console.log(data)
  return (
    <div>
      {/* <div id="full-page-loader">
        <Loader />
      </div> */}
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />{" "}
        <meta
          name="theme-color"
          content="#144a95"
          media="(prefers-color-scheme: light)"
        />
        <meta
          name="theme-color"
          content="#144a95"
          media="(prefers-color-scheme: dark)"
        />
        <meta name="theme-color" content="#144a95" />
      </Helmet>
      <Header
        data={data.allSanitySiteSettings.edges[0].node.headerblock}
        location={location}
      />{" "}
      <main className="relative pt-28">{children}</main>
      <Footer
        data={data.allSanitySiteSettings.edges[0].node}
        newsdata={data.sanityPage}
        location={location}
      />
    </div>
  );
};

export default Layout;

import React, { useState, useEffect } from "react";
// import LogoTitle from "../../assets/images/logo_title.png";
// import Logo from "../../assets/images/header_logo.png";
// import Logo from "../../assets/images/avgss_logo.png"
// import GatsbyImageCustom from "../Images/GatsbyImageCustom";
import Logo from "../../assets/images/AVGSS-Logo-1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  // faArrowDown,
  faBars,
  // faChevronCircleDown,
  // faEnvelope,
  // faLocationArrow,
  // faMapMarkerAlt,
  // faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "gatsby";
// import {
//   faFacebookF,
//   // faFacebookSquare,
//   faInstagram,
// } from "@fortawesome/free-brands-svg-icons";
// import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
// import GatsbyImageCustom from "../Image/GatsbyImageCustom";

const Header = (data) => {
  console.log(data, "data");
  const [hideTopBar, setHideTopBar] = useState();
  const [navBar, setNavBar] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(false);

  // console.log(data, "menu_target_link");

  useEffect(() => {
    if (data?.location && data.location.pathname === "/ami-token") {
      setHideTopBar(true);
    } else {
      setHideTopBar(false);
    }
  }, []);

  return (
    <>
      <div
        className={`fixed ${
          (data?.location && data.location.pathname === "/gallery/") ||
          (data?.location && data.location.pathname === "/")
            ? "z-50"
            : "z-20"
        } w-full`}
      >
        {false && (
          <div
            className="hidden lg:block"
            style={{ backgroundColor: "#52057B" }}
          >
            <div className="max-w-6xl mx-auto flex justify-between py-4">
              {/* <div className="flex"> */}

              {/* </div> */}
              <div className="flex">
                <div className="text-base uppercase font-semibold text-secondary-golden-500 px-4 flex justify-center items-center">
                  Amethhyyst Jalwa Goa
                </div>
                <div className="border-r border-white my-1.5"></div>
                <div className="text-base font-semibold text-secondary-golden-500 px-4 flex justify-center items-center">
                  India's Biggest Bollywood Music Festival
                </div>
                <div className="border-r border-white my-1.5"></div>
                <div className="text-base font-semibold text-secondary-golden-500 px-4 flex justify-center items-center">
                  9 to 11th December
                </div>
              </div>
              <a href="https://in.bookmyshow.com/events/amethhyyst-jalwaa-open-arena/ET00318129">
                <div className="text-sm">
                  <button className="bg-secondary-golden-500 px-6 py-1.5 font-bold text-secondary-darkblue-500 rounded hover:bg-secondary-golden-700  hover:text-secondary-darkblue-500 transition duration-500;">
                    Book Now
                  </button>
                </div>
              </a>

              {/* <div className="text-base  font-semibold text-secondary-darkblue-500">
                  <span className="pr-2">
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      className="text-sm"
                    />
                  </span>
                  Andheri West, Mumbai
                </div> */}
              {/* <span className="border-r-2 mx-2 border-secondary-darkblue-500 flex self-center" />
                <div className="text-base  font-semibold text-secondary-darkblue-500">
                  <span className="pr-2">
                    <FontAwesomeIcon icon={faPhoneAlt} className="text-sm" />
                  </span>
                  <span className="font-bold pr-2">Call :</span>
                  <a href="tel:+91-9320793207">+91 9320793207</a>
                </div> */}
              {/* </div> */}
              {/* <div className="flex">
                <div className="text-base font-semibold text-secondary-darkblue-500">
                  <span className="pr-2">
                    <FontAwesomeIcon icon={faEnvelope} />
                    <i className="far fa-envelope"></i>
                  </span>
                  <span className="font-bold pr-2">Email :</span>
                  <a href="mailto: rince@amethhyyst.com">
                    rince@amethhyyst.com
                  </a>
                </div>
                <span className="border-r border-secondary-linearGradient-300 flex self-center mx-2" />
                <div className="text-base font-semibold text-secondary-darkblue-500">
                  <div className="flex cursor-pointer">
                    <Link
                      to="https://www.facebook.com/amethhyyst/"
                      target="_blank"
                      className="mr-4 "
                    >
                      <FontAwesomeIcon icon={faFacebookF} className="text-sm" />
                    </Link>
                    <Link
                      to="https://www.instagram.com/amethhyyst?utm_medium=copy_link"
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faInstagram} className="text-sm" />
                    </Link>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        )}
        {hideTopBar && (
          <div className="top-header-area">
            Smart Contract Address : &nbsp;
            <a
              rel="noreferrer"
              href="https://xdc.network/token/xdc5d5f074837f5d4618b3916ba74de1bf9662a3fed"
              target="_blank"
            >
              xdc5d5f074837f5d4618b3916ba74de1bf9662a3fed
            </a>
          </div>
        )}

        <div className="bg-secondary-darkblue-500 shadow-lg">
          <div className="max-w-6xl mx-auto">
            <div className="flex text-white justify-between font-bold px-4 py-4">
              <div className="px-4 lg:px-6">
                <Link to="/" className="flex space-x-2">
                  <img src={Logo} className="h-20" alt="img" />
                  {/* <GatsbyImageCustom
                id={data.data._rawHdrlogo.asset._ref}
                className="h-14"
              /> */}
                  {/* <GatsbyImageCustom
                id={data.data._rawHdrnamelogo.asset._ref}
                className="h-12 w-full md:block hidden"
              /> */}
                  {/* <img
                    src={LogoTitle}
                    alt="img-1"
                    className="h-12 w-auto md:block hidden"
                  /> */}
                </Link>
              </div>

              <div className="lg:hidden py-2 flex">
                <div className="block m-auto">
                  <div
                    className="nav-bar-btn"
                    onClick={() => {
                      setNavBar(!navBar);
                    }}
                    onKeyDown={() => {
                      setNavBar(!navBar);
                    }}
                  >
                    <span
                      className="pr-2 flex items-center"
                      style={{ paddingBottom: 3 }}
                    >
                      <FontAwesomeIcon icon={faBars} size={"lg"} />
                    </span>
                    {/* <span className="font-medium"> Menu</span> */}
                  </div>
                </div>
              </div>
              <div
                className={`transition-width transition-height duration-300 ease-in-out ${
                  navBar ? `w-full opacity-100 nav-open` : `w-0 h-0 opacity-0`
                } lg:w-auto lg:h-auto opacity-100 nav-bar-container bg-secondary-darkblue-500`}
              >
                <ul className="flex lg:flex-row flex-col lg:items-center items-start space-x-0 lg:space-x-3 xl:space-x-5 w-full">
                  {data.data.mainnavigation &&
                    data.data.mainnavigation.menu_block.map((link, idx) => {
                      return link.menu_item_link === null ? (
                        <li
                          key={link.menu_item_title}
                          className="relative block lg:inline-block hover:text-white w-full lg:w-full"
                        >
                          <div
                            className="nav-bar-has-sublink cursor-pointer w-20"
                            onClick={() => {
                              setOpenSubMenu(!openSubMenu);
                              // setNavBar(!navBar);
                            }}
                          >
                            <div className="">{link.menu_item_title}</div>
                            <div className="">
                              <FontAwesomeIcon
                                icon={faAngleDown}
                                className="ml-2"
                              />
                            </div>
                          </div>
                          <div
                            className={`min-w-full lg:min-w-200 lg:text-left z-50 ${
                              openSubMenu
                                ? `block border-t-2 lg:border-t-0 border-gray-100`
                                : `hidden`
                            }   lg:absolute lg:pt-6 w-full lg:w-52 top-9 bg-secondary-linearGradient-300 text-white text-base lg:shadow-1xl lg:rounded`}
                          >
                            <ul className="lg:rounded-b-xl lg:pb-2 px-6">
                              {link._rawSubMenuSection.hassubmenu.add_menu_group[0].add_sub_menu_items.map(
                                (subLink) => {
                                  return (
                                    <li
                                      key={subLink.sub_menu_item_title}
                                      className="py-2 z-50"
                                    >
                                      <Link
                                        onClick={() => {
                                          // setOpenSubMenu(!openSubMenu);
                                          setNavBar(!navBar);
                                        }}
                                        to={subLink.sub_menu_item_link}
                                        className="cursor-pointer"
                                      >
                                        {subLink.sub_menu_item_title}
                                      </Link>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </div>
                        </li>
                      ) : (
                        <Link
                          key={idx}
                          to={link.menu_item_link}
                          className="lg:p-0 p-4 w-full"
                        >
                          <li className="w-20">{link.menu_item_title}</li>
                        </Link>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
